import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllProductionTags(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/production/ProductionTag')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllCollectionDevices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/dc-wizard/Device/Devices')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTagGroupsByDeviceGuid(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/dc-wizard/TagGroup/TagGroupsByDeviceGuid?DeviceGuid='+ data.id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTagsByTagGroupId(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/dc-wizard/Tag/TagsByTagGroupId?TagGroupId='+ data.id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllCollectionTags(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/dc-wizard/Tag/Tags')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAllProductionResultTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/production/ProductionResultType')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAllDevices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/device/Device')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createProductionTag(ctx, productionTagData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/production/ProductionTag/Create', productionTagData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateProductionTag(ctx, productionTagData) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/production/ProductionTag/Update', productionTagData, { params: { id: productionTagData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteProductionTag(ctx, productionTagData) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/production/ProductionTag/Delete', { params: { id: productionTagData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTagGroupByTagId(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/dc-wizard/Tag/TagGroupByTagId?TagId='+ data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  }
}
