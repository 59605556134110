<template>
  <div>
    <production-tag-add-new :is-add-new-production-tag-sidebar-active.sync="isAddNewProductionTagSidebarActive"
                  @refetch-data="refetchData"
                  :actionType="actionType"
                  ref="productionTagAddNewComponent" />

    <!-- Table Container Card -->
    <b-card no-body
            class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12"
                 md="6"
                 class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12"
                 md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="search"
                            class="d-inline-block mr-1"
                            placeholder="Search..." />
              <b-button variant="primary"
                        @click="newProductionTag()">
                <span class="text-nowrap">Add Production Tag</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refProductionTagListTable"
               class="position-relative"
               :items="tableItems"
               responsive
               :fields="tableColumns"
               primary-key="id"
               sort-by="id"
               :filter="search"
               show-empty
               empty-text="No matching records found"
               :per-page="perPage"
               :sort-desc.sync="isSortDirDesc"
               :current-page="currentPage">

        <template #cell(isActive)="data">
          <b-badge :variant="isActiveVariant(data.item.isActive)">
            {{ data.item.isActive }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret>
            <template v-slot:button-content>
              <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item @click="changeProductionTag(data.item, 1)">
              <feather-icon
                            icon="EditIcon"
                            class="mr-50" />
              <span>{{ $t("Edit") }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeProductionTag(data.item, 2)">
              <feather-icon
                            icon="TrashIcon"
                            class="mr-50" />
              <span>{{ $t("Delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12"
                 sm="6"
                 class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            <span class="text-muted">Showing {{ this.perPage * (this.currentPage - 1) + 1 }} to {{ this.perPage *
              (this.currentPage) }} of {{ totalItem }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12"
                 sm="6"
                 class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage"
                          :total-rows="totalItem"
                          :per-page="perPage"
                          first-number
                          last-number
                          class="mb-0 mt-1 mt-sm-0"
                          prev-class="prev-item"
                          next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon"
                              size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon"
                              size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useProductionTagList from "./useProductionTagList";
import productionTagStoreModule from "../productionTagStoreModule";
import ProductionTagAddNew from "./ProductionTagAddNew";

export default {
  computed: {
    isActiveVariant() {
      const activeColor = {
        false: 'light-danger',
        true: 'light-success',
      }
      return active => activeColor[active]
    },
  },
  filters: {},
  methods: {
    startMigrating() {
      this.migrationStarted = !this.migrationStarted;
    },
    changeProductionTag(e, actionType) {
      this.selectedProductionTag = JSON.parse(JSON.stringify(e));
      this.isAddNewProductionTagSidebarActive = true;
      this.actionType = actionType;
    },
    newProductionTag() {
      this.selectedProductionTag = {
        id: null,
        productionResultTypeID: null,
        tagID: null,
        deviceID: null,
        isActive: true,
        // isDelete: null,
      };
      this.isAddNewProductionTagSidebarActive = true;
      this.actionType = 0;
    },
    refreshTable: function () {
      refetchData();
    },
  },
  provide() {
    const productionTagData = {};

    Object.defineProperty(productionTagData, "selectedProductionTag", {
      enumerable: true,
      get: () => this.selectedProductionTag,
    });

    return {
      productionTagData,
    };
  },
  components: {
    ProductionTagAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      migrationStarted: false,
      selectedProductionTag: null,
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      search: '',
      actionType: 0
    };
  },
  setup() {
    const PRODUCTIONTAG_APP_STORE_MODULE_NAME = "apps-production-tag";

    // Register module
    if (!store.hasModule(PRODUCTIONTAG_APP_STORE_MODULE_NAME))
      store.registerModule(PRODUCTIONTAG_APP_STORE_MODULE_NAME, productionTagStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCTIONTAG_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCTIONTAG_APP_STORE_MODULE_NAME);
    });

    const isAddNewProductionTagSidebarActive = ref(false);

    const {
      refetchData,
      tableColumns,
      totalItem,
      isSortDirDesc,
      tableItems,
      refProductionTagListTable
    } = useProductionTagList();
    return {
      refetchData,
      isAddNewProductionTagSidebarActive,
      tableColumns,
      totalItem,
      isSortDirDesc,
      tableItems,
      refProductionTagListTable
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
