import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductionTagList() {
  // Use toast
  const toast = useToast()


  // Table Handlers
  const tableColumns = [
    { key: 'id', label: "ID", sortable: true },
    { key: 'productionResultTypeName', label: "Production Result Type", sortable: true },
    { key: 'deviceName', label: "Device", sortable: true },
    { key: 'tagID', label: "Tag ID", sortable: true },
    { key: 'tagName', label: "Tag Name", sortable: true },
    { key: 'isActive', label: "is Active", sortable: true },
    { key: 'actions' },
  ]
  const totalItem = ref(0)
  const tableItems = ref([])
  const refProductionTagListTable = ref(null)
  const isSortDirDesc = ref(true)
  const collectionTags = ref([])

  function GetCollectionTags() {
    return new Promise((resolve, reject) => {
      store.dispatch('apps-production-tag/fetchAllCollectionTags')
      .then(response => {
        var collectionTagResult = [];

        if(response.data != null && response.data.length > 0){
          collectionTagResult = response.data
          resolve(collectionTagResult);
          collectionTags.value = collectionTagResult;
        } else {
          reject(collectionTagResult)
        }
      })
      .catch(() => {
        reject(collectionTagResult)
      })
    })
  }

  const refetchData = () => {
    store
      .dispatch('apps-production-tag/fetchAllProductionTags', {
      })
      .then(response => {
        response.data = response.data.filter(x => !x.isDelete);
        store
          .dispatch('apps-production-tag/fetchAllProductionResultTypes', {
          })
          .then(response2 => {
            response2.data = response2.data.filter(x => !x.isDelete);
            response.data.forEach(item => {
              item.productionResultTypeName = '';
              if (item.productionResultTypeID != 0 && response2.data.find(x => x.id == item.productionResultTypeID) != null) {
                item.productionResultTypeName = response2.data.find(x => x.id == item.productionResultTypeID).resultName;
                item.tagName = collectionTags.value.find(x => x.id == item.tagID).name;
              }
            });
            store
              .dispatch('apps-production-tag/fetchAllDevices', {
              })
              .then(response3 => {
                response3.data = response3.data.filter(x => !x.isDelete);
                response.data.forEach(item => {
                  item.deviceName = '';
                  if (item.deviceID != 0 && response3.data.find(x => x.id == item.deviceID) != null) {
                    item.deviceName = response3.data.find(x => x.id == item.deviceID).name;
                  }
                });
                tableItems.value = response.data;
                totalItem.value = response.data.length;
              })
              .catch(error => reject(error));
          })
          .catch(error => reject(error));
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Production Tag list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function GetData(){
    await GetCollectionTags();
    refetchData();
  }

  GetData()

  return {
    refetchData,
    tableColumns,
    tableItems,
    isSortDirDesc,
    totalItem,
    refProductionTagListTable
  }
}
