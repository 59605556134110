<template>
  <b-sidebar id="add-new-production-tag-sidebar"
             :visible="isAddNewProductionTagSidebarActive"
             bg-variant="white"
             sidebar-class="sidebar-lg"
             shadow
             backdrop
             no-header
             right
             @hidden="resetForm"
             @change="(val) => $emit('update:is-add-new-production-tag-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Production Tag" : actionType == 1 ? " Update Production Tag" : "Delete Production Tag" }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)" @reset.prevent="resetForm">

          <b-form-group label="Production Result Type"
                        label-for="production-result-type-id">
            <production-result-type-picker :initialProductionResultTypes="currentProductionTag.productionResultTypeID"
                                           :actionType="actionType"
                                           @selectionChanged="onProductionResultTypeSelectionChanged"
                                           ref="productionResultTypePickerComponent">
            </production-result-type-picker>
          </b-form-group>

          <b-form-group label="Production Device"
                        label-for="device-id">
            <device-picker :initialDevices="currentProductionTag.deviceID"
                           :actionType="actionType"
                           @selectionChanged="onDeviceSelectionChanged"
                           ref="devicePickerComponent">
            </device-picker>
          </b-form-group>

          <b-form-group label="Data Collection Device">
            <v-select
              id="device"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentCollectionDevice"
              label="deviceName"
              :options="dataCollectionDevices"
              v-on:input="changeCollectionDevice"
              :disabled="actionType==2"
              class="select-size-lg">
                <template #option="{ id, deviceName }">
                    <span> {{ deviceName }}</span><br />
                    <small class="text-muted"> {{ id }} </small>
                </template>
            </v-select>
          </b-form-group>

          <b-form-group v-if="currentCollectionDevice != null" label="Data Collection Tag Group">
            <v-select
              id="tagGroup"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentCollectionTagGroup"
              label="name"
              :options="dataCollectionTagGroups"
              v-on:input="changeTagGroup"
              :disabled="actionType==2"
              class="select-size-lg">
                <template #option="{ id, name }">
                    <span> {{ name }}</span><br />
                    <small class="text-muted"> {{ id }} </small>
                </template>
            </v-select>
          </b-form-group>

          <b-form-group v-if="currentCollectionTagGroup != null"  label="Data Collection Tag">
                  <v-select
                          id="tag"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          v-model="currentCollectionTag"
                          label="name"
                          :options="dataCollectionTags"
                          :disabled="actionType==2"
                          class="select-size-lg">
                  <template #option="{ id, name }">
                      <span> {{ name }}</span><br />
                      <small class="text-muted"> {{ id }} </small>
                  </template>
                  </v-select>
              </b-form-group>

          <validation-provider
                               name="Is Active">
            <b-form-group label=""
                          label-for="isActive">
              <b-form-checkbox class="mt-2"
                               :disabled="actionType == 2"
                               id="isActive"
                               v-model="currentProductionTag.isActive">
                Is Active
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                      v-if="currentProductionTag == null || currentProductionTag.id == null">
              Add
            </b-button>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                      @click="updateProductionTag"
                      v-if="currentProductionTag != null && currentProductionTag.id != null && actionType == 1">
              Update
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
                      v-if="currentProductionTag != null && currentProductionTag.id != null && actionType == 2"
                      variant="outline-danger"
                      @click="deleteProductionTag">
              Delete
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductionResultTypePicker from '../components/ProductionResultTypePicker.vue'
import DevicePicker from '../components/DevicePicker.vue'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    DevicePicker,
    ProductionResultTypePicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProductionTagSidebarActive',
    event: 'update:is-add-new-production-tag-sidebar-active',
  },
  props: {
    isAddNewProductionTagSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    changeCollectionDevice(){
      this.currentCollectionTagGroup = null;
      this.currentCollectionTag = null;
    },
     changeTagGroup(){
      this.currentCollectionTag = null;
    },
    GetTagGroupByTagId(data) {
      this.$store.dispatch('apps-production-tag/fetchTagGroupByTagId', data)
        .then((result) => {
          if (result != null) {
            this.tagGroup = result.data;
            const device = {};
            device.id = result.data.deviceID;
            device.deviceName = result.data.deviceName;
            this.currentCollectionDevice = device;
            const tagGroup = {};
            tagGroup.id = result.data.tagGroupID;
            tagGroup.name = result.data.tagGroupName;
            this.currentCollectionTagGroup = tagGroup;
            const tag = {};
            tag.id = result.data.id;
            tag.name = result.data.name;
            this.currentCollectionTag = tag;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Tag Group By Tag Id By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    GetCollectionDevices() {
      this.$store.dispatch('apps-production-tag/fetchAllCollectionDevices')
        .then((result) => {
          if (result != null) {
            this.dataCollectionDevices = result.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Colection Device By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    GetTagGroupsByDeviceGuid(data) {
      this.$store.dispatch('apps-production-tag/fetchTagGroupsByDeviceGuid', data)
        .then((result) => {
          if (result != null) {
            this.dataCollectionTagGroups = result.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Colection Tag Group By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    GetCollectionTags(data) {
      this.$store.dispatch('apps-production-tag/fetchTagsByTagGroupId', data)
        .then((result) => {
          if (result != null) {
            this.dataCollectionTags = result.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Colection tags By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    onProductionResultTypeSelectionChanged(item) {
      this.currentProductionTag.productionResultTypeID = (item != null && item.id != null) ? item.id : 0;
    },
    onDeviceSelectionChanged(item) {
      this.currentProductionTag.deviceID = (item != null && item.id != null) ? item.id : 0;
    },
    deleteProductionTag() {
      this.$store.dispatch('apps-production-tag/deleteProductionTag', this.currentProductionTag)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-production-tag-sidebar-active', false)
          this.notification()
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Production Tag Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    updateProductionTag() {
      if (this.currentProductionTag.productionResultTypeID == null || this.currentProductionTag.productionResultTypeID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Production Result Type Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentProductionTag.deviceID == null || this.currentProductionTag.deviceID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Device Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentCollectionTag.id == null || this.currentCollectionTag.id < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tag can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else {
        this.currentProductionTag.tagID = this.currentCollectionTag.id;
        this.$store.dispatch('apps-production-tag/updateProductionTag', this.currentProductionTag)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-production-tag-sidebar-active', false)
            // this.$emit('show-success-toast')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Production Tag Successfully Updated',
                icon: 'BellIcon',
              },
            })
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Production Tag Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
    },
    submitForm() {
      if (this.currentProductionTag.productionResultTypeID == null || this.currentProductionTag.productionResultTypeID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Production Result Type Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentProductionTag.deviceID == null || this.currentProductionTag.deviceID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Device Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentCollectionTag.id == null || this.currentCollectionTag.id < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tag can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else {
        this.currentProductionTag.tagID = this.currentCollectionTag.id;
        this.$store.dispatch('apps-production-tag/createProductionTag', this.currentProductionTag)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-production-tag-sidebar-active', false)
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Create Production Tag Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },
 created() {
    this.GetCollectionDevices();
  },
  watch: {
    currentCollectionDevice(data) {
      if (!this.isInitialEdit) {
        this.currentCollectionTagGroup = null;
      }

      if (data != null) {
        this.GetTagGroupsByDeviceGuid(data);
      }
    },
    currentCollectionTagGroup(data) {
      if (!this.isInitialEdit) {
        this.currentCollectionTag = null;
      }      

      if (data != null) {
        this.GetCollectionTags(data);
      }
    },
    productionTagData: {
      handler: function (n, o) {
        if (n == null || n == undefined || n.selectedProductionTag.tagID == null) {
          this.currentProductionTag = {
            id: null,
            productionResultTypeID: null,
            tagID: null,
            deviceID: null,
            isActive: false,
          }
          this.currentCollectionDevice = null;
          this.currentCollectionTagGroup = null;
        } else {
          this.isInitialEdit = false;
          this.currentProductionTag = n.selectedProductionTag;
          
           if(n.selectedProductionTag.tagID != null){
              this.isInitialEdit = true;
              this.GetTagGroupByTagId(n.selectedProductionTag.tagID);
            } else {
              this.currentCollectionTag = this.dataCollectionTags.find(x=>x.id == this.currentCollectionTag.id);
            }
        }
      },
      deep: true
    }

  },
  data() {
    return {
      required,
      currentProductionTag: null,
      dataCollectionTags :[],
      dataCollectionDevices :[],
      dataCollectionTagGroups :[],
      tagGroup:null,
      device:null,
      currentCollectionTag : null,
      currentCollectionDevice : null,
      currentCollectionTagGroup : null,
      isInitialEdit : false
    }
  },
  inject: ['productionTagData'],
  setup(props, { emit }) {
    const blankProductionTagData = {
      id: null,
      productionResultTypeID: null,
      deviceID: null,
      tagID: null,
      isActive: false,
    }
    const productionTagData = ref(JSON.parse(JSON.stringify(blankProductionTagData)))
    const {
      getValidationState,
    } = formValidation(productionTagData)

    return {
      productionTagData,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-production-tag-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
